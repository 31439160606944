<template>
  <div>
    <div>
      <a-tabs type="card" class="w-full" @change="changeTabs" :activeKey="activeTab">
        <a-tab-pane :key="1" tab="结算表"></a-tab-pane>
        <a-tab-pane :key="2" tab="财务账户"></a-tab-pane>
        <a-tab-pane :key="3" tab="钱包充值"></a-tab-pane>
      </a-tabs>
    </div>

    <template v-if="activeTab==1">
    	<div class="search-bar">
	      <a-form-model layout="inline">
	        <a-form-model-item prop="search_date" label="选择时间">
	          <a-range-picker style="width:260px"
	          	:disabled="loading_group"
	            v-model="search_date"
	            valueFormat="YYYY-MM-DD"
	          />
	        </a-form-model-item>
	        <a-form-model-item>
	          <a-button type="primary" :loading="loading_group" 
	          	@click="handlerSearchGroup">计算所有财务账户的内部结算</a-button>
	          <span class="ml-3" style="color:#999;font-size:12px;">*大约需要2分钟</span>
	        </a-form-model-item>
	      </a-form-model>
	    </div>
      <div class="text-sm mt-4 mb-2">结算表（单位：元）</div>
      <table class="settle-table-group">
    		<thead>
    			<tr>
    				<td rowspan="2">本账户</td>
    				<td rowspan="2">目标账户</td>
    				<td colspan="2">支付到本账户、目标账户交付</td>
    				<td colspan="2">支付到目标账户、本账户交付</td>
    				<td colspan="4">本账户钱包到目标账户消费</td>
    				<td colspan="4">目标账户钱包到本账户消费</td>
    				<td colspan="2">本账户券到目标账户消费</td>
    				<td colspan="2">目标账户券到本账户消费</td>
    				<td rowspan="2">合计收入<br>从目标账户</td>
    			</tr>
    			<tr>
    				<td>交付结算(支)</td>
    				<td>售后退款(收)</td>
    				<td>交付结算(收)</td>
    				<td>售后退款(支)</td>
    				<td>钱包消费-本金(支)</td>
    				<td>钱包消费(支)</td>
    				<td>消费退款-本金(收)</td>
    				<td>消费退款(收)</td>
    				<td>钱包消费-本金(收)</td>
    				<td>钱包消费(收)</td>
    				<td>消费退款-本金(支)</td>
    				<td>消费退款(支)</td>
    				<td>券消费(支)</td>
    				<td>券退款(收)</td>
    				<td>券消费(收)</td>
    				<td>券退款(支)</td>
    			</tr>
    		</thead>
    		<tbody v-if="loading_group">
    			<tr>
    				<td colspan="19">
							<div class="no-data">
								<!-- 加载中... -->
								{{load_group_msg}}
							</div>
						</td>
    			</tr>
    		</tbody>
    		<tbody v-else-if="tableData_settle && tableData_settle.length">
    			<tr v-for="(item,index) in tableData_settle" :key="index" :value="item">
              <td>{{ item.name }}</td>
              <td>{{ item.tname }}</td>
              <td>{{ formatEnMoney(item.amount_1,  false, true) }}</td>
              <td>{{ formatEnMoney(item.amount_3,  false, false) }}</td>
              <td>{{ formatEnMoney(item.amount_2,  false, false) }}</td>
              <td>{{ formatEnMoney(item.amount_4,  false, true) }}</td>
              <td>{{ formatEnMoney(item.amount_5,  false, true) }}</td>
              <td>{{ formatEnMoney(item.amount_5_t,false, true) }}</td>
              <td>{{ formatEnMoney(item.amount_6,  false, false) }}</td>
              <td>{{ formatEnMoney(item.amount_6_t,false, false) }}</td>
              <td>{{ formatEnMoney(item.amount_7,  false, false) }}</td>
              <td>{{ formatEnMoney(item.amount_7_t,false, false) }}</td>
              <td>{{ formatEnMoney(item.amount_8,  false, true) }}</td>
              <td>{{ formatEnMoney(item.amount_8_t,false, true) }}</td>
              <td>{{ formatEnMoney(item.amount_9,  false, true) }}</td>
              <td>{{ formatEnMoney(item.amount_10, false, false) }}</td>
              <td>{{ formatEnMoney(item.amount_11, false, false) }}</td>
              <td>{{ formatEnMoney(item.amount_12, false, true) }}</td>
              <td>{{ formatEnMoney(item.total_amount) }}</td>
          </tr>
    		</tbody>
    		<tbody v-else>
    			<tr>
    				<td colspan="9">
							<div class="no-data">暂无数据</div>
						</td>
    			</tr>
    		</tbody>
    	</table>
    </template>

    <template v-if="activeTab==2">
    	<div class="search-bar">
	      <a-form-model layout="inline">
	        <a-form-model-item prop="financial_account_id" label="财务账户">
	          <a-select style="width:300px;" placeholder="请选择账户"
							:disabled="loading_finance"
							v-model="financial_account_id"
							@change="changeAccount">
	            <a-select-option v-for="(financeAccount, index) in financeAccountList"
	                 :value="financeAccount.id"
	                 :key="index">{{financeAccount.name}}</a-select-option>
	          </a-select>
	        </a-form-model-item>
	        <a-form-model-item prop="search_date" label="选择时间">
	          <a-range-picker style="width:260px"
							:disabled="loading_finance"
	            v-model="search_date"
	            valueFormat="YYYY-MM-DD"
	          />
	        </a-form-model-item>
	        <a-form-model-item>
	          <a-button type="primary" :loading="loading_finance" 
	          		@click="handlerSearchFinance">搜索</a-button>
	        </a-form-model-item>
	      </a-form-model>
	    </div>

			<div class="text-sm mt-4 mb-2">“{{selectAccount.name}}”订单总览（根据下单时间筛选）</div>
			<base-table style="max-width:800px;"
				:columnsData="columns_order"
				:tableData="tableData_order"
			></base-table>
			<div class="text-sm mt-4 mb-2">“{{selectAccount.name}}”退费数据（根据退费时间筛选）</div>
			<base-table style="max-width:400px;"
				:columnsData="columns_refund"
				:tableData="tableData_refund"
			></base-table>
			<div class="text-sm mt-4 mb-2">“{{selectAccount.name}}”订单来源分布（单位:笔）</div>
			<base-table
				:columnsData="columns_order_source"
				:tableData="tableData_source"
			></base-table>
			<div class="text-sm mt-4 mb-2">“{{selectAccount.name}}”支付方式分布（单位:元）</div>
			<base-table 
				:columnsData="columns_order_pay"
				:tableData="tableData_pay"
			></base-table>
			
			<!-- <div class="text-sm mt-4 mb-2">“{{selectAccount.name}}”的钱包充值</div>
			<base-table style="max-width:400px;"
				:columnsData="columns_vipcard_recharge"
				:tableData="tableData_vipcard"
			></base-table>
			<div class="text-sm mt-4 mb-2">“{{selectAccount.name}}”的钱包在线消费</div>
			<base-table style="max-width:400px;"
				:columnsData="columns_vipcard_online"
				:tableData="tableData_vipcard"
			></base-table>
			<div class="text-sm mt-4 mb-2">“{{selectAccount.name}}”的钱包线下消费</div>
			<base-table style="max-width:400px;"
				:columnsData="columns_vipcard_offline"
				:tableData="tableData_vipcard"
			></base-table> -->
    </template>
		
		<template v-if="activeTab==3">
			<div class="search-bar">
	      <a-form-model layout="inline">
	        <a-form-model-item prop="recharge_financial_account_id" label="钱包账户">
	          <a-select style="width:300px;" placeholder="请选择账户"
							:disabled="loading_recharge"
							v-model="recharge_financial_account_id" 
							@change="changeRechargeAccount">
							<a-select-option :value="0" :key="0">不限</a-select-option>
	            <a-select-option v-for="item in financeRechargeAccountList"
	            		:value="item.id" :key="item.id">{{item.name}}</a-select-option>
	          </a-select>
	        </a-form-model-item>
	        <a-form-model-item prop="recharge_search_date" label="选择时间">
	          <a-range-picker style="width:260px"
							:disabled="loading_recharge"
	            v-model="recharge_search_date"
	            valueFormat="YYYY-MM-DD"
	          />
	        </a-form-model-item>
	        <a-form-model-item>
	          <a-button type="primary" :loading="loading_recharge" 
	          		@click="handlerSearchRecharge">搜索</a-button>
	        </a-form-model-item>
	      </a-form-model>
	    </div>
    	<div class="recharge mt-4">
    		<template v-if="rechargeData">
    			<div>
    				<div v-if="rechargeData.finance_name" class="mb-1">{{rechargeData.finance_name}}</div>
    				<template v-if="rechargeData.date_start == rechargeData.date_end">{{rechargeData.date_start}} 的充值总额：</template>
    				<template v-else>{{rechargeData.date_start}}~{{rechargeData.date_end}} 的充值总额：</template>
    			</div>
    			<div class="num mt-8">本金：￥{{rechargeData.vip_card_recharge_amount}}</div>
    			<div class="num mt-1">（含赠送总额：￥{{rechargeData.vip_card_recharge_amount_m}}）</div>
    		</template>
    		<template v-else>暂无数据</template>
    	</div>
    </template>
  </div>
</template>

<script>
import pageData from "./columns"
import {
  getList,
} from "@/api/system/account.js"
import { 
	financeGroupSettle,
	financeGroupAccountOrder,
	financeGroupAccountVipcard,
	financeAccountVipcardRecharge
} from "@/api/finance/finance.js"
import { formatEnMoney } from "@/utils/index.js"

export default {
  data() {
    return {
    	formatEnMoney,

			activeTab: 1,
			search_date: [],
			financial_account_id: '',

			financeAccountList: [],
			selectAccount: {},

			...pageData,

			columns_order_source: [],
			columns_order_pay: [],

			tableData_order: [],
			columns_order: [
				{
					title: "有效订单总数",
					dataIndex: "place_order_count",
					align: "center",
				},
				{
					title: "有效订单总金额(元)",
					dataIndex: "order_amount",
					align: "center",
					slots: {
						customRender: "order_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "其中已完成订单数",
					dataIndex: "finish_count",
					align: "center",
				},
				{
					title: "已完成订单总金额(元)",
					dataIndex: "finish_amount",
					align: "center",
					slots: {
						customRender: "finishamount",
					},
					slotsType: "format",
					// slotsFunc: (val) => {return formatEnMoney(val)},
					slotsFunc: (val) => {return val ? val : "——"},
				},
			],
			tableData_refund: [],
			columns_refund: [
				{
					title: "退费订单数",
					dataIndex: "refund_order_count",
					align: "center",
				},
				{
					title: "退费金额(元)",
					dataIndex: "refund_amount",
					align: "center",
					slots: {
						customRender: "refund_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
			],

			tableData_pay: [],

			tableData_source: [],

			tableData_vipcard: [],
			columns_vipcard_recharge: [
				{
					title: "充值笔数",
					dataIndex: "recharge_order_count",
					align: "center",
				},
				{
					title: "充值金额(元)",
					dataIndex: "recharge_denomination",
					align: "center",
					slots: {
						customRender: "recharge_denomination",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				// {
				// 	title: "充值支付金额(元)",
				// 	dataIndex: "recharge_pay_amount",
				// 	align: "center",
				// 	slots: {
				// 		customRender: "recharge_pay_amount",
				// 	},
				// 	slotsType: "format",
				// 	slotsFunc: (val) => {return formatEnMoney(val)},
				// },
			],

			columns_vipcard_online: [
				{
					title: "在线消费笔数",
					dataIndex: "online_consumption_order_count",
					align: "center",
				},
				{
					title: "在线消费金额(元)",
					dataIndex: "online_consumption_amount",
					align: "center",
					slots: {
						customRender: "online_consumption_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				// {
				// 	title: "对应充值金额(元)",
				// 	dataIndex: "online_consumption_recharge_discount_amount",
				// 	align: "center",
				// 	slots: {
				// 		customRender: "online_consumption_recharge_discount_amount",
				// 	},
				// 	slotsType: "format",
				// 	slotsFunc: (val) => {return formatEnMoney(val)},
				// },
			],

			columns_vipcard_offline: [
				{
					title: "线下消费笔数",
					dataIndex: "offline_consumption_order_count",
					align: "center",
				},
				{
					title: "线下消费金额(元)",
					dataIndex: "offline_consumption_amount",
					align: "center",
					slots: {
						customRender: "offline_consumption_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				// {
				// 	title: "对应充值金额(元)",
				// 	dataIndex: "offline_consumption_recharge_discount_amount",
				// 	align: "center",
				// 	slots: {
				// 		customRender: "offline_consumption_recharge_discount_amount",
				// 	},
				// 	slotsType: "format",
				// 	slotsFunc: (val) => {return formatEnMoney(val)},
				// },
			],

			tableData_settle: [],

			financeRechargeAccountList: [],
			recharge_search_date: [],
			recharge_financial_account_id: 0,
			selectRechargeAccount: null,
			rechargeData: null,

			loading_group: false,
			loading_finance: false,
			load_group_msg: "",
			loading_recharge: false,
    }
  },
  mounted() {
    this.initData()

    this.search_date = [
			// this.getThisMonthFirstDay(),
			this.getToday(),
			this.getToday(),
		]
		this.recharge_search_date = [
			this.getToday(),
			this.getToday(),
		]
		
  },
  destroyed(){
  	// 终止遍历加载
  	this.loading_group = false
  	// this.loading_finance = false
  },

  methods: {
    changeTabs(v){
      this.activeTab = v
    },

    async initData(){
			const { data } = await getList()
			this.financeAccountList = data.list
			this.financial_account_id = data.list[0].id
			this.selectAccount = data.list[0]

			this.financeRechargeAccountList = data.list.filter(el=>el.outlets_city)
    },

    changeAccount(id){
			let finance = this.financeAccountList.find(el=> el.id == id )
			this.selectAccount = finance
    },

    async initDataSettle() {
    	const params = {
				time_start: this.search_date[0] + ' 00:00:00',
				time_end:   this.search_date[1] + ' 23:59:59'
			}
			// 串行加载（每个财务账户加载一次、再合并）
			this.load_group_msg = "加载中"
			this.loading_group = true
			let finance_list = JSON.parse(JSON.stringify(this.financeAccountList))
			const result = await this.initSingleSettle(finance_list, params)
			if(!result) return
			const resList = result.list
			this.loading_group = false

			// 预处理
			let list = []
			resList.map(fitem=>{
				fitem.other_financial_settle_accounts_list.map(el=>{
					let myItem = list.find(fi=>{
						return (fi.id == fitem.financial_account_id) && (fi.tid == el.financial_account_id)
					})
					if(!myItem){
						myItem = {
							id:   fitem.financial_account_id,
							name: fitem.financial_account_name,
							tid:   el.financial_account_id,
							tname: el.financial_account_name,
							amount_1: 0,
							amount_2: 0,
							amount_3: 0,
							amount_4: 0,
							amount_5: 0,
							amount_6: 0,
							amount_7: 0,
							amount_8: 0,
							amount_9: 0,
							amount_10: 0,
							amount_11: 0,
							amount_12: 0,
						}
						list.push(myItem)
					}
					Object.assign(myItem, {
            amount_7_t:  el.amount_7.amount,
            amount_8_t:  el.amount_8.amount,
						amount_1:  el.amount_1,
            amount_3:  el.amount_3,
            amount_7:  el.amount_7.discount_amount,
            amount_8:  el.amount_8.discount_amount,
            amount_9:  el.amount_9,
            amount_10: el.amount_10,
					})
					// 
					let taItem = list.find(fi=>{
						return (fi.id == el.financial_account_id) && (fi.tid == fitem.financial_account_id)
					})
					if(!taItem){
						taItem = {
							id:   el.financial_account_id,
							name: el.financial_account_name,
							tid:   fitem.financial_account_id,
							tname: fitem.financial_account_name,
							amount_1: 0,
							amount_2: 0,
							amount_3: 0,
							amount_4: 0,
							amount_5: 0,
							amount_6: 0,
							amount_7: 0,
							amount_8: 0,
							amount_9: 0,
							amount_10: 0,
							amount_11: 0,
							amount_12: 0,
						}
						list.push(taItem)
					}
					Object.assign(taItem, {
            amount_5_t:  el.amount_7.amount,
            amount_6_t:  el.amount_8.amount,
						amount_2:  el.amount_1,
            amount_4:  el.amount_3,
            amount_5:  el.amount_7.discount_amount,
            amount_6:  el.amount_8.discount_amount,
            amount_11: el.amount_9,
            amount_12: el.amount_10,
					})
				})
			})
			list.map(item=>{
				let total = -Number(item.amount_1)  + Number(item.amount_3)
										+Number(item.amount_2)  - Number(item.amount_4) 
									  -Number(item.amount_5)  + Number(item.amount_6) 
										+Number(item.amount_7)  - Number(item.amount_8)
										-Number(item.amount_9)  + Number(item.amount_10)
										+Number(item.amount_11) - Number(item.amount_12)
				item.total_amount = total
			})

			this.tableData_settle = list
    },
    async initSingleSettle(finance_list, time_params, result) {
    	if(!this.loading_group){
    		return null
    	}
    	result = result || {
    		time: 0,
    		list: []
    	}
    	if(finance_list.length){
    		const financial_account = finance_list.shift()
    		const params = {
					...time_params,
					financial_account_id: financial_account.id,
				}

    		let time = new Date().getTime()
				const { data, code } = await financeGroupSettle(params)
				time = new Date().getTime()-time
				result.time = result.time+time

				const _percent = Math.round((this.financeAccountList.length-finance_list.length) / this.financeAccountList.length * 100)
				const _lefttime = Math.round(result.time / _percent * (100 - _percent) / 1000)
				this.load_group_msg = "总进度" + _percent+"%，剩余时长"+_lefttime+"秒，正在计算 "+financial_account.name

				if(code == 0){
					result.list = result.list.concat(data.list || [])
				}else{
					this.$message.warning("数据错误 请重试，"+financial_account.name)
					return {
						time: 0,
						list: []
					}
				}
				return await this.initSingleSettle(finance_list, time_params, result)
    	}else{
    		return result
    	}
    },

    async initDataOrder() {
			const params = {
				financial_account_id: this.financial_account_id,
				time_start: this.search_date[0] + ' 00:00:00',
				time_end:   this.search_date[1] + ' 23:59:59'
			}
			const { data, code } = await financeGroupAccountOrder(params)

			if (code === 0) {
				// 全部
				data.order_source_data['0'] = data.place_order_count
				data.order_amount_data['0'] = data.order_amount

				// 支付方式分布：第三方明细
				for(let j in data.order_amount_data['50_amount_data']){
					data.order_amount_data['50_amount_data_'+j] = formatEnMoney(data.order_amount_data['50_amount_data'][j])
				}
				// 支付方式分布 金额格式化
				for(let i in data.order_amount_data){
					data.order_amount_data[i] = formatEnMoney(data.order_amount_data[i])
				}

				// for(let i in data.order_amount_data){
				// 	if(i == "50_amount_data"){
				// 		// data.order_amount_data[i] = formatEnMoney(data.order_amount_data[i])
				// 		let _50_amount_data = data.order_amount_data[i]
				// 		for(let j in _50_amount_data){
				// 			_50_amount_data[j] = formatEnMoney(_50_amount_data[j])
				// 		}
				// 	}else{
				// 		data.order_amount_data[i] = formatEnMoney(data.order_amount_data[i])
				// 	}
				// }
				this.tableData_order = [data]
				this.tableData_refund = [data]
				this.tableData_source = [data.order_source_data]
				this.tableData_pay = [data.order_amount_data]
			}
    },

    async initDataVipcard() {
			const params = {
				financial_account_id: this.financial_account_id,
				time_start: this.search_date[0] + ' 00:00:00',
				time_end:   this.search_date[1] + ' 23:59:59'
			}
			const { data, code } = await financeGroupAccountVipcard(params)
			if (code === 0) {
				this.tableData_vipcard = [data]
			}
    },

    changeRechargeAccount(id){
			let finance = this.financeRechargeAccountList.find(el=> el.id == id )
			this.selectRechargeAccount = finance
    },
    async initDataVipcardRecharge() {
    	if(this.loading_recharge) return
    	this.rechargeData = null
    	this.loading_recharge = true
			const params = {
				financial_account_id: this.recharge_financial_account_id || "",
				time_start: this.recharge_search_date[0] + ' 00:00:00',
				time_end:   this.recharge_search_date[1] + ' 23:59:59'
			}
			const { data, code } = await financeAccountVipcardRecharge(params)
    	this.loading_recharge = false
			if (code === 0) {
				this.rechargeData = {
					vip_card_recharge_amount: formatEnMoney(data.vip_card_recharge_amount),
					vip_card_recharge_amount_m: formatEnMoney(data.vip_card_recharge_amount_m),
					finance_name: this.selectRechargeAccount ? this.selectRechargeAccount.name : "",
					date_start: this.recharge_search_date[0],
					date_end: this.recharge_search_date[1],
				}
			}
    },


		handlerSearchGroup(){
			if (this.search_date.length < 2) {
				this.$message.warning("请选择日期")
				return
			}

			this.initDataSettle()
		},
		async handlerSearchFinance(){
			if(!this.financial_account_id){
				this.$message.warning("请选择账户")
				return
			}
			if (this.search_date.length < 2) {
				this.$message.warning("请选择日期")
				return
			}

			this.columns_order_source = this.columns_source_simple.filter(el=>{
				if(this.financial_account_id == 1){
					return el.isTechComp != 1
				}else{
					return el.isTechComp != 2
				}
			})
			this.columns_order_pay = this.columns_pay_simple.filter(el=>{
				if(this.financial_account_id == 1){
					return el.isTechComp != 1
				}else{
					return el.isTechComp != 2
				}
			})
			this.loading_finance = true
			await this.initDataOrder()
			// await this.initDataVipcard()
			this.loading_finance = false
		},
		handlerSearchRecharge(){
			if (this.recharge_search_date.length < 2) {
				this.$message.warning("请选择日期")
				return
			}
			this.initDataVipcardRecharge()
		},

		// -----------
		getLastMonthFirstDay(){
			const today = new Date()
			let month = today.getMonth()
			let year = today.getFullYear()
			// 1月：上一个月 是去年12月
			if(month == 0){
				month = 11
				year = year-1
			}else{
				month = month-1
			}
			return this.formatDate(new Date(year, month, 1))
    },
    getLastMonthLastDay(){
			let date = new Date()
			date.setTime(date.getTime() - date.getDate()*24*3600*1000)

			return this.formatDate(date)
    },
    getThisMonthFirstDay(){
			const today = new Date()
			let month = today.getMonth()
			let year = today.getFullYear()
			return this.formatDate(new Date(year, month, 1))
    },
    getToday(){
			let date = new Date()
			return this.formatDate(date)
    },

    formatDate(date){
			let year = date.getFullYear()
			let month = date.getMonth()
			let day = date.getDate()
			month = month+1
			if(month < 10){
				month = "0"+month
			}
			if(day < 10){
				day = "0"+day
			}
			return year+"-"+month+"-"+day
    },

  },
}
</script>

<style lang="less">
.settle-table-group{
	width: 100%;
	border: 1px solid #e8e8e8;
	border-collapse: collapse;
	text-align: center;
	thead{
		background: #fafafa;
		color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
		td{
			padding: 10px 8px;
		}
	}
	td{
		border: 1px solid #e8e8e8;
		padding: 8px;
		line-height: 20px;
	}
	.no-data{
		text-align: center;
		padding: 40px;
	}
	.link-btn{
		cursor: pointer;
		color: #1890ff;
		font-weight: 400;
	}
}
.recharge{
	background: #f1f1f1;
  border-radius: 20px;
  width: 400px;
  height: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .num{
  	font-size: 20px;
  }
}
</style>